<template>
    <div class="auth-wrapper auth-v1"
         style="padding: 0">

        <div class="auth-inner">
            <v-row>
                <v-col cols="12"
                       lg="12"
                       md="12"
                       sm="12"
                       class="mx-auto">
                    <v-card class=""
                            flat>
                        <app-header></app-header>
                        <div class="mx-lg-8 mx-md-4 mx-sm-3 mx-3">
                            <v-row>
                                <v-col cols="12"
                                       lg="12">
                                    <div class="mt-5">
                                        <div style="margin-left: -18px;margin-right: -18px;margin-bottom: 13px;">
                                            <BackAction :text="text"
                                                        :output="output" />
                                        </div>

                                        <p class="font-weight-semibold text-black text-h6 pl-3 getInTouch">
                                            {{ $t('Get in touch') }}
                                        </p>
                                    </div>
                                </v-col>
                            </v-row>
                            <v-row class="white-card-background">
                                <v-col cols="12"
                                       lg="4"
                                       md="8"
                                       sm="12">
                                    <!-- <v-container class="mx-7"> -->
                                    <v-sheet class="pa-5 px-6 rounded pb-9 security">
                                        <div v-for="(item, index) in items"
                                             :key="item.icon">
                                            <v-sheet :class="{ selected: select === index }"
                                                     class="pa-3 rounded-lg mb-2 pointer "
                                                     @click="showComponent(item, index)">
                                                <v-avatar color="#E2F1FD"
                                                          size="28">
                                                    <v-icon color="#006FCF"
                                                            size="17">{{ item.icon }}</v-icon>
                                                </v-avatar>
                                                <span class="font-weight-medium text-tab text-black"
                                                      style="font-size: 14px;"
                                                      :class="{
                                                          'text-white': select === index,
                                                          'ml-2': selectedLanguage == 'en',
                                                          'mr-2': selectedLanguage == 'ar',
                                                      }">{{ $t(item.text) }}</span>
                                                <v-icon v-if="selectedLanguage == 'en'"
                                                        class="chevron-right"
                                                        style="margin-top:3px"
                                                        :class="{ selected: select === index }">
                                                    {{ item.icon2 }}</v-icon>
                                                <v-icon v-if="selectedLanguage == 'ar'"
                                                        class="chevron-right"
                                                        style="margin-top:3px"
                                                        :class="{ selected: select === index }">
                                                    {{ item.iconAr }}</v-icon>
                                            </v-sheet>
                                        </div>

                                        <v-sheet class="pa-3 rounded-lg mb-2">
                                            <v-row>
                                                <v-col lg="6">
                                                    <div class="mt-3 main-tab">
                                                        <v-avatar color="#E2F1FD"
                                                                  size="28">
                                                            <v-icon color="#006FCF"
                                                                    size="17">mdi-phone</v-icon>
                                                        </v-avatar>
                                                        <span :class="{ 'ml-2': selectedLanguage == 'en', 'mr-2': selectedLanguage == 'ar' }"
                                                              style="font-size: 14px;"
                                                              class="font-weight-medium tabs-contact text-black">
                                                            {{ $t('Call Us') }}
                                                        </span>
                                                    </div>
                                                </v-col>

                                                <v-col lg="6"
                                                       dir="ltr">
                                                    <span style="color: #006fcf"
                                                          class="font-weight-medium text-caption">
                                                        <a href="tel:8001242229">800 124 2229</a>
                                                    </span><br />
                                                    <span style="color: #006fcf"
                                                          class="font-weight-medium text-caption">
                                                        <a href="tel:+966112926666">(+966) 11 292 6666</a>
                                                    </span>
                                                </v-col>
                                            </v-row>
                                        </v-sheet>

                                        <v-sheet class="pa-3 rounded-lg mb-2 main-tab">
                                            <v-avatar color="#E2F1FD"
                                                      size="28">
                                                <v-icon color="#006FCF"
                                                        size="17">mdi-lightning-bolt</v-icon>
                                            </v-avatar>
                                            <span style="font-size: 14px;"
                                                  :class="{ 'ml-2': selectedLanguage == 'en', 'mr-2': selectedLanguage == 'ar' }"
                                                  class="font-weight-medium text-black">{{ $t('Send mail') }}</span>
                                            <div :class="{ 'ml-9': selectedLanguage == 'en', 'mr-9': selectedLanguage == 'ar' }"
                                                 class="font-size">
                                                {{ $t('American Express Saudi Arabia') }}<br />
                                                {{ $t('P.O Box: 6624') }}<br />
                                                {{ $t('Riyadh 1145, Kingdom of Saudi Arabia') }}
                                            </div>
                                        </v-sheet>

                                        <div v-for="(item, index) in items1"
                                             :key="item.icon"
                                             main-tab>
                                            <v-sheet class="pa-3 rounded-lg mb-2 pointer main-tab"
                                                     :class="{ selected: selected === index }"
                                                     @click="component(item, index)">
                                                <v-avatar color="#E2F1FD"
                                                          size="28">
                                                    <v-icon color="#006FCF"
                                                            size="17">{{ item.icon }}</v-icon>
                                                </v-avatar>
                                                <span tabs-contact
                                                      style="font-size: 14px;"
                                                      :class="{
                                                          'text-white': selected === index,
                                                          'ml-2': selectedLanguage == 'en',
                                                          'mr-2': selectedLanguage == 'ar',
                                                      }"
                                                      class="font-weight-medium text-black">{{ $t(item.text) }}</span>
                                                <v-icon tabs-contact
                                                        v-if="selectedLanguage == 'en'"
                                                        class="chevron-right"
                                                        :class="{ selected: selected === index }">{{ item.icon2
                                                        }}</v-icon>
                                                <v-icon tabs-contact
                                                        v-if="selectedLanguage == 'ar'"
                                                        class="chevron-right"
                                                        :class="{ selected: selected === index }">{{ item.iconAr
                                                        }}</v-icon>
                                            </v-sheet>
                                        </div>
                                    </v-sheet>
                                    <!-- </v-container> -->
                                </v-col>

                                <v-col cols="12"
                                       lg="8"
                                       md="12"
                                       sm="12"
                                       v-if="showEmail">
                                    <email-us title="Email Us"
                                              :selectedLanguage="selectedLanguage"
                                              placeholder="Department"></email-us>
                                </v-col>

                                <v-col cols="12"
                                       lg="8"
                                       md="12"
                                       sm="12"
                                       v-if="showFeedback">
                                    <feed-back title="Share your feedback"
                                               label="Feedback topic"
                                               :selectedLanguage="selectedLanguage"
                                               placeholder="Select topic"></feed-back>
                                </v-col>

                                <v-col cols="12"
                                       lg="4"
                                       md="12"
                                       sm="12"
                                       v-if="showAtmBranches">
                                    <atm-branches @details="onDetails($event)"
                                                  :atms="atmInformation"
                                                  :selectedLanguage="selectedLanguage"
                                                  class="mt-2"></atm-branches>
                                </v-col>

                                <v-col cols="12"
                                       lg="4"
                                       md="12"
                                       sm="12"
                                       v-if="showAtmBranches">
                                    <!-- <v-btn @click="getDirections"></v-btn> -->
                                    <!-- <v-container class="show-map"> -->
                                    <v-sheet class="pa-4 pb-5 rounded balance-card-background"
                                             color="#F8F8F8">
                                        <gmap-map :bounds="bounds"
                                                  ref="mapRef"
                                                  id="map"
                                                  v-if="isMap"
                                                  :center="center"
                                                  :zoom=zoom
                                                  style="width: 100%; height: 375px">
                                            <gmap-marker :key="index"
                                                         v-for="(m, index) in markers"
                                                         :position="m.position"
                                                         :id="m.id"
                                                         @click="center = m"></gmap-marker>
                                            <GmapDirection :origin="origin"
                                                           :destination="destination"
                                                           :waypoints="waypoints"
                                                           :options="{ suppressMarkers: true }" />
                                        </gmap-map>
                                    </v-sheet>
                                    <!-- </v-container> -->
                                </v-col>
                            </v-row>
                        </div>
                    </v-card>
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
import EmailUs from './EmailUs.vue'
import AppHeader from '../test/AppHeader.vue'
import CallUs from './CallUs.vue'
import AtmBranches from './AtmBranches.vue'
import FeedBack from './FeedBack.vue'
import moment from 'moment'
import { DeviceUUID } from 'device-uuid'
import soapServices from '@/services/soap-services'
import store from '@/store'
import sharedServices from '@/services/shared-services'
import BackAction from '@/@core/BackAction.vue';
export default {
    name: 'Home',
    components: {
        EmailUs,
        CallUs,
        AtmBranches,
        FeedBack,
        AppHeader,
        BackAction
    },
    data()
    {
        return {
            select: 0,
            origin: null, // the origin of the directions (the user's current location, for example)
            destination: null, // the destination of the directions (the last marker, in this case)
            waypoints: [],
            selected: null,
            showEmail: true,
            isMap: false,
            showCallus: false,
            showFeedback: false,
            zoom: 15,
            selectedLanguage: 'en',
            showAtmBranches: false,
            atmInformation: null,
            text: 'Back to Card Details',
            output: false,
            center: { lat: 24.7136, lng: 46.6753 },
            markers: [],
            bounds: null,
            // markerIcon: 'http://maps.google.com/mapfiles/ms/icons/purple-dot.png',
            currentPlace: null,
            items: [
                {
                    icon: 'mdi-email',
                    text: 'Email Us',
                    icon2: 'mdi-chevron-right',
                    iconAr: 'mdi-chevron-left',
                    showEmail: true,
                },
                // { icon: 'mdi-phone', text: 'Call us', icon2: 'mdi-chevron-right', showCallus: true },
            ],
            items1: [
                {
                    icon: 'mdi-forum',
                    text: 'Share your feedback',
                    iconAr: 'mdi-chevron-left',
                    icon2: 'mdi-chevron-right',
                    showFeedback: true,
                },
                {
                    icon: 'mdi-map-marker',
                    text: 'Nearby ATMs/Branches',
                    iconAr: 'mdi-chevron-left',
                    icon2: 'mdi-chevron-right',
                    showAtmBranches: true,
                },
            ],
        }
    },
    computed: {
        mrClass()
        {
            if (this.selectedLanguage === 'ar')
            {
                return 'mr-2'
            } else
            {
                return 'ml-2'
            }
        },
    },
    created()
    {
        store.subscribe(mutation =>
        {

            if (mutation.type === 'accounts/SET_LANGUAGE')
            {
                this.selectedLanguage = mutation.payload;
            }
        })
    },
    mounted()
    {
        let lang = localStorage.getItem('language')
        if (lang)
        {
            this.selectedLanguage = localStorage.getItem('language')
        }

    },
    methods: {
        getDirections()
        {
            this.destination = { lat: 24.706666666666667, lng: 46.67527777777777 }
        },
        getNearbyATM()
        {
            let lat = this.center?.lat;
            let lon = this.center?.lng;

            let latSeconds = parseInt(lat * 3600);
            let latDegrees = parseInt(latSeconds / 3600);
            let latitudeDegree = `${latDegrees}`;
            latSeconds = parseInt(latSeconds % 3600);
            let latMinutes = parseInt(latSeconds / 60);
            let latitudeMinute = `${latMinutes}`;

            let latitudeSecond = parseInt(latSeconds % 60);
            let latitudeDirection = lat > 0 ? 'N' : 'S';

            let lonSeconds = lon * 3600;
            let lonDegrees = parseInt(lonSeconds / 3600);
            let longitudeDegree = `${lonDegrees}`;
            lonSeconds = lonSeconds % 3600;
            let lonMinutes = parseInt(lonSeconds / 60);
            let longitudeMinute = `${lonMinutes}`;

            let longitudeSecond = parseInt(lonSeconds % 60);
            let longitudeDirection = lon > 0 ? 'E' : 'W';

            let obj = {
                messageStamp: moment(new Date()).format('DD/MM/yyyy HH:mm:ss'),
                sysId: new DeviceUUID().get(),
                authToken: store.get('requestKeys/accessToken') ?? '',
                idSeed: sharedServices.getIdSeed(),
                latitudeDirection: latitudeDirection,
                latitudeDegree: latitudeDegree,
                latitudeMinute: latitudeMinute,
                latitudeSecond: latitudeSecond,
                longitudeDirection: longitudeDirection,
                longitudeDegree: longitudeDegree,
                longitudeMinute: longitudeMinute,
                longitudeSecond: longitudeSecond,
            }

            soapServices.getNearbyAtms(obj).then(soapResp =>
            {
                if (
                    soapResp.json.findLocationOffersResponse.findLocationOffersResult.GeoLocationOffers.Result.ErrorCode == '000'
                )
                {
                    this.atmInformation = soapResp.records;

                    for (let index = 0; index < soapResp.records.length; index++)
                    {
                        var latDeg = soapResp.records[index].Latitude_Degree
                            ? parseFloat(soapResp.records[index].Latitude_Degree)
                            : '0'
                        var latMin = soapResp.records[index].Latitude_Minute
                            ? parseFloat(soapResp.records[index].Latitude_Minute)
                            : '0'
                        var latSec = soapResp.records[index].Latitude_Second
                            ? parseFloat(soapResp.records[index].Latitude_Second)
                            : '0'
                        var latDir = 0
                        if (soapResp.records[index].Latitude_Direction == 'N')
                        {
                            latDir = 1
                        } else if (soapResp.records[index].Latitude_Direction == 'S')
                        {
                            latDir = -1
                        }

                        var latitude = latDir * (latDeg + latMin / 60 + latSec / 3600)

                        var lonDeg = soapResp.records[index].Longitude_Degree
                            ? parseFloat(soapResp.records[index].Longitude_Degree)
                            : '0'
                        var lonMin = soapResp.records[index].Longitude_Minute
                            ? parseFloat(soapResp.records[index].Longitude_Minute)
                            : '0'
                        var lonSec = soapResp.records[index].Longitude_Second
                            ? parseFloat(soapResp.records[index].Longitude_Second)
                            : '0'
                        var lonDir = 0
                        if (soapResp.records[index].Longitude_Direction == 'E')
                        {
                            lonDir = 1
                        } else if (soapResp.records[index].Longitude_Direction == 'W')
                        {
                            latDir = -1
                        }

                        var longitude = lonDir * (lonDeg + lonMin / 60 + lonSec / 3600);

                        this.markers.push({
                            position: { lat: latitude, lng: longitude },
                            label: soapResp.records[index].LocationName_Eng,
                            id: soapResp.records[index].LocationID,
                        })
                    }
                } else
                {
                }
            })
        },
        onDetails(event)
        {

            this.markers.forEach(element =>
            {
                if (event.LocationName_Eng == element.label)
                {
                    this.center = element.position;
                    this.zoom = 20;
                    return
                }
            });
        },
        geolocate()
        {
            this.isMap = true
            navigator.geolocation.getCurrentPosition(position =>
            {
                this.center = {
                    lat: 24.7136,
                    lng: 46.6753,
                }
            })
        },
        showComponent(item, index)
        {
            this.select = index
            this.selected = null
            this.showFeedback = false;
            this.showAtmBranches = false;

            this.showEmail = item.showEmail;
            // this.showCallus = item.showCallus
        },

        component(item, index)
        {
            this.select = null
            this.selected = index
            this.showEmail = false
            this.showCallus = false

            if (item.showFeedback)
            {
                this.showFeedback = true;
                this.showAtmBranches = false;
            }
            else
            {
                this.showFeedback = false;
                this.showAtmBranches = true;
                this.geolocate()
                this.getNearbyATM()
            }
        },
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.font-size {
    font-size: 10px;
    font-weight: 450;
}

.pointer {
    cursor: pointer;
}

.text-white {
    color: white;
}

.map {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    /* margin-top: 60px; */
}

.show-map {
    margin: 64px 0px 0px -26px;
}

.selected {
    color: white !important;
    background-color: #016fd0;
}

@media (max-width: 1280px) {

    .mx-7 {
        margin-right: 0px !important;
        margin-left: 0px !important;
    }
}

@media (max-width: 1236px) {
    .show-map {
        margin: 0px 0px 0px 0px !important;
    }
}

@media (max-width: 438px) {
    .main-tab {
        display: flex;
    }

    .tabs-contact {
        display: block;
    }

    .text-tab text-black {
        margin-top: 4px;

    }
}
</style>
