<template>
    <!-- <v-container> -->
    <div>
        <div v-if="showAlert">
            <alert :message="alertMessage"
                   :type="alertType"></alert>

        </div>
        <v-sheet class="pa-4 pb-7 px-6 rounded security">
            <div style="font-size: 14px;"
                 class="font-weight-semibold pb-4 pt-1">{{ $t(title) }}</div>
            <v-row class="department">
                <v-col cols="12"
                       lg="5"
                       md="12"
                       sm="12">
                    <div v-if="title != 'Share your feedback'"
                         style="font-size: 14px;"
                         class="text-color-black">
                        {{ $t('Department') }}{{ '*' }}
                    </div>
                    <v-select v-if="title != 'Share your feedback' && selectedLanguage == 'en'"
                              :items="departments"
                              solo
                              flat
                              :rules="departmentRules"
                              v-model="department"
                              outlined
                              class="rounded-lg mt-2 method"
                              :placeholder="$t('Department')">
                        <template #message="{ message }">
                            {{ $t(message) }}
                        </template>
                    </v-select>
                    <v-select v-if="title != 'Share your feedback' && selectedLanguage == 'ar'"
                              :items="departmentsAr"
                              solo
                              flat
                              :rules="departmentRules"
                              v-model="department"
                              outlined
                              class="rounded-lg mt-2 method"
                              :placeholder="$t('Department')">
                        <template #message="{ message }">
                            {{ $t(message) }}
                        </template>
                    </v-select>
                    <div v-if="title == 'Share your feedback'"
                         style="font-size: 14px;"
                         class="text-color-black">
                        {{ $t('Feedback topic') }}{{ '*' }}
                    </div>
                    <v-select hide-details="auto"
                              v-if="title == 'Share your feedback' && selectedLanguage == 'en'"
                              :items="topics"
                              solo
                              v-model="feedBack"
                              outlined
                              :rules="feedbackRules"
                              flat
                              class="rounded-lg mt-2 method"
                              :placeholder="$t('Feedback topic')">
                        <template #message="{ message }">
                            {{ $t(message) }}
                        </template>
                    </v-select>
                    <v-select hide-details="auto"
                              v-if="title == 'Share your feedback' && selectedLanguage == 'ar'"
                              :items="topicsAr"
                              solo
                              v-model="feedBack"
                              :rules="feedbackRules"
                              outlined
                              flat
                              class="rounded-lg mt-2 method"
                              :placeholder="$t('Feedback topic')">
                        <template #message="{ message }">
                            {{ $t(message) }}
                        </template>
                    </v-select>
                </v-col>
                <v-col cols="12"
                       lg="5"
                       md="12"
                       sm="12">
                    <div style="font-size: 14px;"
                         class="text-color-black">
                        {{ $t('Full Name') }}{{ '*' }}
                    </div>
                    <v-text-field hide-details="auto"
                                  class="mt-2 rounded-lg"
                                  :rules="name"
                                  :placeholder="$t('Full name')"
                                  solo
                                  outlined
                                  v-model="fullName"
                                  flat>
                        <template #message="{ message }">
                            {{ $t(message) }}
                        </template>
                    </v-text-field>
                </v-col>
                <v-col cols="12"
                       lg="5"
                       md="12"
                       sm="12">
                    <div style="font-size: 14px;"
                         class="pb-2 text-color-black">
                        {{ $t('Email') }}{{ '*' }}
                    </div>
                    <v-text-field hide-details="auto"
                                  class="rounded-lg"
                                  outlined
                                  type="email"
                                  :rules="emails"
                                  v-model="email"
                                  :placeholder="$t('abc@xyz.com')"
                                  solo
                                  flat>
                        <template #message="{ message }">
                            {{ $t(message) }}
                        </template>
                    </v-text-field>
                </v-col>
                <v-col cols="12"
                       lg="5"
                       md="12"
                       sm="12">
                    <div style="font-size: 14px;"
                         class="pb-2 text-color-black">
                        {{ $t('Contact number') }}{{ '*' }}
                    </div>
                    <v-text-field hide-details="auto"
                                  class="rounded-lg"
                                  type="text"
                                  v-mask="'##########'"
                                  placeholder="05xxxxxxxx"
                                  outlined
                                  :rules="numbers"
                                  v-model="number"
                                  :placeholder="$t('Contact number')"
                                  solo
                                  flat>
                        <template #message="{ message }">
                            {{ $t(message) }}
                        </template>
                    </v-text-field>
                </v-col>
                <v-col cols="12"
                       lg="10">
                    <!-- <div class="text-color-black text-body-2 pb-2">Additional information*</div> -->
                    <div class="d-flex justify-space-between">
                        <p class="mb-2 text-color-black">
                            {{ $t('Additional information*') }}
                        </p>
                        <p class="mb-2">
                            {{ information.length }}{{ $t('/500') }}
                        </p>
                    </div>
                    <v-textarea v-model="information"
                                solo
                                outlined
                                maxlength="500"
                                :rules="genericRules"
                                :placeholder="$t('Add Message')">
                        <template #message="{ message }">
                            {{ $t(message) }}
                        </template>
                    </v-textarea>
                </v-col>
            </v-row>


            <v-row>
                <v-col cols="12"
                       lg="4"
                       md="6"
                       sm="12">
                    <a id="email">
                        <v-btn color="primary"
                               x-large
                               @click="sendMail"
                               :disabled="feedBack == null || fullName == null || email == null || number == null || information == ''"
                               block>
                            {{ $t('Send') }}
                        </v-btn>
                    </a>
                </v-col>
            </v-row>
        </v-sheet>
    </div>
    <!-- </v-container> -->
</template>
  
<script>
import alert from '../alert.vue';
import getArabicNumbers from '@/@core/utils/getArabicNumbers';
export default {
    name: 'EmailUs',
    components: {
        alert
    },

    props: {
        title: {
            type: String,
            default: ''
        },
        label: {
            type: String,
            default: ''
        },
        placeholder: {
            type: String,
            default: ''
        },
        selectedLanguage: {
            type: String,
            default: 'en'
        }
    },
    data()
    {
        return {
            rules: [v => v.length <= 500 || 'Max 500 characters'],
            counter: 0,
            department: null,
            body: 'Dear Sir/Madam,%0DPlease prioritize the below message and resolve any issue at your earliest.%0D',

            feedBack: null,
            engCount: null,
            information: '',
            alertMessage: '',
            alertType: 'error',
            showAlert: false,
            fullName: null,
            isValidEmail: false,
            email: null,
            number: null,

            genericRules: [
                v => !!v || 'Field is required'
            ],
            // emails: [
            //     v => !!v || 'Email is required'
            // ],
            emails: [
                v => !!v || 'Email is required',
                v => /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/.test(v) || 'incorrect_email_format'
            ],
            numbers: [
                v => !!v || 'Contact number is required',
                value => /^05\d{8}$/.test(value) || 'Please enter a  mobile number consisting of 10 digits  (05xxxxxxxx)'
            ],
            name: [
                v => !!v || 'Full name is required'
            ],
            feedbackRules: [
                v => !!v || 'Feedback topic is required'
            ],
            // numbers: [
            //     v => !!v || 'Contact number is required'
            // ],
            departmentRules: [
                v => !!v || 'Please select department'
            ],
            departments: ['Customer Service', 'Corporate Service', 'Direct Sales', 'Platinum Service', 'Merchant Service'],
            topics: ['My Card is not working', 'Where are my Points/Miles', 'Card is lost/stolen', 'I didn’t receive my Card', 'Other'],
            topicsAr: ['بطاقتي لا تعمل', 'أين أجد نقاطي / أميالي', 'الإبلاغ عن سرقة / ضياع بطاقتي', 'لم أستلم بطاقتي', 'أخرى'],
            departmentsAr: [
                'خدمة العملاء',
                'خدمات الشركات',
                'قسم المبيعات',
                'الخدمات البلاتينية',
                'الخدمات التجارية'
            ],
            checkDepartments: [
                {
                    email: "customerservice@americanexpress.com.sa",
                    subject: "Customer Service",
                    body: '',
                    subject_ar: 'خدمة العملاء',
                },
                {
                    email: "corporate.services@americanexpress.com.sa",
                    subject: "Corporate Service",
                    subject_ar: 'خدمات الشركات',
                    body: ''
                },
                {
                    email: "directsales@americanexpress.com.sa",
                    subject: "Direct Sales",
                    subject_ar: 'قسم المبيعات',
                    body: ''
                },
                {
                    email: "platinumcare@americanexpress.com.sa",
                    subject: "Platinum Service",
                    subject_ar: 'الخدمات البلاتينية',
                    body: ''
                },
                {
                    email: "merchants@americanexpress.com.sa",
                    subject: "Merchant Service",
                    subject_ar: 'الخدمات التجارية',
                    body: ''
                }
            ],
        }
    },
    methods: {
        validateEmail()
        {
            const emailRegex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
            this.isValidEmail = emailRegex.test(this.email);
        },

        sendMail()
        {
            let emailData = null;

            // let emailData = this.checkDepartments.filter((elem) => elem == this.department);

            if (this.selectedLanguage == 'en')
            {
                emailData = this.checkDepartments.find((elem) => elem.subject == this.department);
            } else
            {
                emailData = this.checkDepartments.find((elem) => elem.subject_ar == this.department);
            }
            console.log(emailData);


            let firstTwoChars = this.number?.slice(0, 2);
            if (firstTwoChars == '05')
            {
                var link = document.getElementById('email');
                // var email = "corporate.services@americanexpress.com.sa";
                var linker = "mailto:" + emailData?.email + "?subject=Data&body=";
                // this.body += 'Subject:' + this.department + '%0D'
                // this.body += 'Message:' + this.information + '%0D'
                // this.body += 'You can contact message sender at the following %0D'
                // this.body += 'Name:' + this.fullName + '%0D'
                // this.body += 'Mobile:' + this.number + '%0D'
                // this.body += 'Email:' + this.email + '%0D'





                this.body = this.selectedLanguage == 'en'
                    ? `Dear Sir/Madam,%0D
                    %0D%0APlease prioritize the below message and resolve any issue at your earliest.%0D
                    %0D%0ASubject: ${this.department}%0D
                    %0D%0AMessage:%0D%0A${this.information}%0D
                    %0D%0AYou can contact message sender at the following:%0D
                    %0D%0AName: ${this.fullName}%0D
                    %0D%0AMobile: ${this.number}%0D
                    %0D%0AEmail Address:${this.email}%0D
                    `
                    : `حضرة السيد/ السيدة،%0D
                    %0D%0Aيرجى تحديد الأولويات الخاصة بالرسالة أدناه وحلّ أي مشكلة مطروحة في أقرب وقت ممكن.%0D
                    %0D%0Aالموضوع:  ${this.department}%0D
                    %0D%0Aرسالة: %0D%0A${this.information}
                    %0D%0Aيمكن الاتصال بمرسل الرسالة عبر:%0D
                    %0D%0Aالإسم: ${this.fullName}%0D
                    %0D%0Aالجوال: ${this.number}%0D
                    %0D%0Aعنوان البريد الإلكتروني: ${this.email}%0D

                    `;

                linker += this.body;

                // %0D%0APlatform: ${ Constants.dataManager.platfromName }
                // %0D%0AVersion:${ Constants.dataManager.packageversion }
                // %0D%0Aالبرنامج: ${Constants.dataManager.platfromName}
                // %0D%0Aالإصدار: ${Constants.dataManager.packageversion}


                link.setAttribute("href", linker);
            }
            else
            {
                let alertMessage = 'emailUsMobileNumSubTitle'
                this.alertMessage = alertMessage
                this.alertType = 'error'
                this.showAlert = true
                setTimeout(() =>
                {
                    this.showAlert = false
                }, 5000)
            }
        }
    }
}
</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.btn {
    background: white;
}

.department {
    margin: 0px -14px -38px;
}

::v-deep .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)>.v-input__control>.v-input__slot {
    box-shadow: none !important;
}
</style>
  
