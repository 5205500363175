<template>
    <!-- <v-container> -->
    <v-sheet class="pa-4 pb-7 rounded balance-card-background">
        <div class="text-caption font-weight-medium text--primary pb-4 mt-n1">{{ $t('Nearby ATMs/Branches') }}</div>
        <v-sheet class="pa-3 rounded"
                 style="overflow: auto; max-height: 334px;">
            <div v-for="(card, index) in atms"
                 :key="index">

                <v-avatar style="cursor: pointer;"
                          color="#E2F1FD"
                          size="26">
                    <v-icon color="#006FCF"
                            size="15">mdi-map-marker</v-icon>
                </v-avatar>
                <v-icon style="cursor: pointer;"
                        @click="onDetails(card)"
                        :class="{ 'float-right': selectedLanguage == 'en', 'float-left': selectedLanguage == 'ar' }"
                        class="mt-2"
                        color="#006FCF">mdi-directions</v-icon>
                <div style="cursor: pointer;"
                     class="text--secondary mt-1 font">{{ card.Distance | VMask(currencyMask) }}</div>
                <div @click="onDetails(card)"
                     style="cursor: pointer;"
                     :class="{ 'pl-11': selectedLanguage == 'en', 'pr-11': selectedLanguage == 'ar' }"
                     class="font-weight-medium text--primary text-caption mt-n12">{{ card.LocationName_Eng }}<br>{{
                         card.City_Eng }}</div>
                <!-- <div :class="{ 'pl-11': selectedLanguage == 'en', 'pr-11':selectedLanguage == 'ar'  }" class="font mt-0 text--secondary">{{ card.LocationName_Eng }}<br>{{ card.City_Eng }}</div> -->
                <v-divider class="mt-3 mb-3"></v-divider>
            </div>
        </v-sheet>
    </v-sheet>
    <!-- </v-container> -->
</template>
  
<script>
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
const currencyMask = createNumberMask({
    prefix: '',
    allowDecimal: true,
    includeThousandsSeparator: true,
    allowNegative: false,
});
export default {
    name: 'AtmBranches',
    props: {
        atms: {
            default: '',
            type: Array
        },
        selectedLanguage: {
            default: 'en',
            type: String
        },

    },
    data()
    {
        return {
            message: 'Riyadh',
            currencyMask,
            cards: [
                { icon: 'mdi-map-marker', icon2: 'mdi-directions', title: 'Alinma', distance: '0.2 mi', text: 'Olaya Mall, Olaya Street', city: 'Riyadh' },
                { icon: 'mdi-map-marker', icon2: 'mdi-directions', title: 'Alinma', distance: '0.3 mi', text: 'Al Olaya Avenio, Al Oroba Street,', city: 'Riyadh' },
                { icon: 'mdi-map-marker', icon2: 'mdi-directions', title: 'SABB', distance: '0.4 mi', text: 'Mobily C1 Building Mini', city: 'Riyadh' },
                { icon: 'mdi-map-marker', icon2: 'mdi-directions', title: 'Alinma', distance: '0.5 mi', text: 'Al Sanad Room Musaad Al Anqari', city: 'Riyadh' },
            ]
        }
    },
    methods: {
        getLocations(card)
        {
            console.log(card);
        },
        onDetails(card)
        {
            this.$emit('details', card)
        }
    }
}
</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.balance-card-background {
    background-color: #f5f5f5;
}

.font {
    font-size: 11px;
    font-weight: 400;
}
</style>
  