var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.showAlert)?_c('div',[_c('alert',{attrs:{"message":_vm.alertMessage,"type":_vm.alertType}})],1):_vm._e(),_c('v-sheet',{staticClass:"pa-4 pb-7 px-6 rounded security"},[_c('div',{staticClass:"font-weight-semibold pb-4 pt-1",staticStyle:{"font-size":"14px"}},[_vm._v(_vm._s(_vm.$t(_vm.title)))]),_c('v-row',{staticClass:"department"},[_c('v-col',{attrs:{"cols":"12","lg":"5","md":"12","sm":"12"}},[(_vm.title != 'Share your feedback')?_c('div',{staticClass:"text-color-black",staticStyle:{"font-size":"14px"}},[_vm._v(" "+_vm._s(_vm.$t('Department'))+_vm._s('*')+" ")]):_vm._e(),(_vm.title != 'Share your feedback' && _vm.selectedLanguage == 'en')?_c('v-select',{staticClass:"rounded-lg mt-2 method",attrs:{"hide-details":"auto","items":_vm.departments,"solo":"","flat":"","rules":_vm.departmentRules,"outlined":"","placeholder":_vm.$t('Department')},scopedSlots:_vm._u([{key:"message",fn:function(ref){
var message = ref.message;
return [_vm._v(" "+_vm._s(_vm.$t(message))+" ")]}}],null,false,3598329216),model:{value:(_vm.department),callback:function ($$v) {_vm.department=$$v},expression:"department"}}):_vm._e(),(_vm.title != 'Share your feedback' && _vm.selectedLanguage == 'ar')?_c('v-select',{staticClass:"rounded-lg mt-2 method",attrs:{"hide-details":"auto","items":_vm.departmentsAr,"solo":"","flat":"","rules":_vm.departmentRules,"outlined":"","placeholder":_vm.$t('Department')},scopedSlots:_vm._u([{key:"message",fn:function(ref){
var message = ref.message;
return [_vm._v(" "+_vm._s(_vm.$t(message))+" ")]}}],null,false,3598329216),model:{value:(_vm.department),callback:function ($$v) {_vm.department=$$v},expression:"department"}}):_vm._e(),(_vm.title == 'Share your feedback')?_c('div',{staticClass:"text-color-black",staticStyle:{"font-size":"14px"}},[_vm._v(" "+_vm._s(_vm.$t('Feedback topic'))+_vm._s('*')+" ")]):_vm._e(),(_vm.title == 'Share your feedback' && _vm.selectedLanguage == 'en')?_c('v-select',{staticClass:"rounded-lg mt-2 method",attrs:{"hide-details":"auto","items":_vm.topics,"solo":"","outlined":"","rules":_vm.feedbackRules,"flat":"","placeholder":_vm.$t('Feedback topic')},scopedSlots:_vm._u([{key:"message",fn:function(ref){
var message = ref.message;
return [_vm._v(" "+_vm._s(_vm.$t(message))+" ")]}}],null,false,3598329216),model:{value:(_vm.feedBack),callback:function ($$v) {_vm.feedBack=$$v},expression:"feedBack"}}):_vm._e(),(_vm.title == 'Share your feedback' && _vm.selectedLanguage == 'ar')?_c('v-select',{staticClass:"rounded-lg mt-2 method",attrs:{"hide-details":"auto","items":_vm.topicsAr,"solo":"","rules":_vm.feedbackRules,"outlined":"","flat":"","placeholder":_vm.$t('Feedback topic')},scopedSlots:_vm._u([{key:"message",fn:function(ref){
var message = ref.message;
return [_vm._v(" "+_vm._s(_vm.$t(message))+" ")]}}],null,false,3598329216),model:{value:(_vm.feedBack),callback:function ($$v) {_vm.feedBack=$$v},expression:"feedBack"}}):_vm._e()],1),_c('v-col',{attrs:{"cols":"12","lg":"5","md":"12","sm":"12"}},[_c('div',{staticClass:"text-color-black",staticStyle:{"font-size":"14px"}},[_vm._v(" "+_vm._s(_vm.$t('Full Name'))+_vm._s('*')+" ")]),_c('v-text-field',{staticClass:"mt-2 rounded-lg",attrs:{"hide-details":"auto","placeholder":_vm.$t('Full name'),"rules":_vm.name,"solo":"","outlined":"","flat":""},scopedSlots:_vm._u([{key:"message",fn:function(ref){
var message = ref.message;
return [_vm._v(" "+_vm._s(_vm.$t(message))+" ")]}}]),model:{value:(_vm.fullName),callback:function ($$v) {_vm.fullName=$$v},expression:"fullName"}})],1),_c('v-col',{attrs:{"cols":"12","lg":"5","md":"12","sm":"12"}},[_c('div',{staticClass:"pb-2 text-color-black",staticStyle:{"font-size":"14px"}},[_vm._v(" "+_vm._s(_vm.$t('Email'))+_vm._s('*')+" ")]),_c('v-text-field',{staticClass:"rounded-lg",attrs:{"hide-details":"auto","placeholder":_vm.$t('abc@xyz.com'),"outlined":"","type":"email","rules":_vm.emailRule,"solo":"","flat":""},scopedSlots:_vm._u([{key:"message",fn:function(ref){
var message = ref.message;
return [_vm._v(" "+_vm._s(_vm.$t(message))+" ")]}}]),model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})],1),_c('v-col',{attrs:{"cols":"12","lg":"5","md":"12","sm":"12"}},[_c('div',{staticClass:"pb-2 text-color-black",staticStyle:{"font-size":"14px"}},[_vm._v(" "+_vm._s(_vm.$t('Contact number'))+_vm._s('*')+" ")]),_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('##########'),expression:"'##########'"}],ref:"textInput",staticClass:"rounded-lg",attrs:{"hide-details":"auto","label":_vm.$t('Contact number'),"outlined":"","type":"text","placeholder":"05xxxxxxxx","rules":_vm.numbers,"solo":"","flat":""},scopedSlots:_vm._u([{key:"message",fn:function(ref){
var message = ref.message;
return [_vm._v(" "+_vm._s(_vm.$t(message))+" ")]}}]),model:{value:(_vm.number),callback:function ($$v) {_vm.number=$$v},expression:"number"}})],1),_c('v-col',{attrs:{"cols":"12","lg":"10"}},[_c('div',{staticClass:"d-flex justify-space-between"},[_c('p',{staticClass:"mb-2 text-color-black"},[_vm._v(" "+_vm._s(_vm.$t('Additional information*'))+" ")]),_c('p',{staticClass:"mb-2"},[_vm._v(" "+_vm._s(_vm.information.length)+_vm._s(_vm.$t('/500'))+" ")])]),_c('v-textarea',{attrs:{"solo":"","outlined":"","maxlength":"500","rules":_vm.genericRules,"placeholder":_vm.$t('Add Message')},scopedSlots:_vm._u([{key:"message",fn:function(ref){
var message = ref.message;
return [_vm._v(" "+_vm._s(_vm.$t(message))+" ")]}}]),model:{value:(_vm.information),callback:function ($$v) {_vm.information=$$v},expression:"information"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"4","md":"6","sm":"12"}},[_c('a',{attrs:{"id":"email"}},[_c('v-btn',{staticClass:"mt-3",attrs:{"color":"primary","x-large":"","disabled":_vm.department == null || _vm.fullName == null || _vm.email == null || _vm.number == null || _vm.information == null,"block":""},on:{"click":_vm.sendMail}},[_vm._v(" "+_vm._s(_vm.$t('Send'))+" ")])],1)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }