<template>
    <!-- <v-container> -->
      <v-sheet class="pa-4 rounded" color="#F8F8F8">
        <div class="text-caption text--primary font-weight-medium pb-2 px-1 mt-n2">Call us</div>
        <v-sheet class="pa-3 rounded">
          <div v-for="item in items" :key="item.title" class="px-lg-5">
            <span class="text-caption font-weight-medium text--primary">{{ item.title }}</span>
            <span class="text-caption font-weight-medium float-right">{{ item.phone }}</span>
            <p class="font text--secondary mt-1">
              {{ item.text }} <span class="primary--text text-decoration-underline">{{ item.online }}</span>
              <span class="float-right">{{ item.time }}</span>
            </p>
            <v-divider class="mb-2 mt-sm-5 mt-9"></v-divider>
          </div>
  
        </v-sheet>
      </v-sheet>
    <!-- </v-container> -->
  </template>
  
  <script>
  export default {
    name: 'CallUs',
    data() {
      return {
        items: [
          { title: 'Customer Service', phone: '1-800-528-4800', text: 'Already a Card Member? Get help with your existing account.', time: '24/7' },
          { title: 'TTY/TDD', phone: 'Relay: Dial 711 and 1-800-528-4800', text: 'Hearing Impaired TTY', time: '24/7' },
          { title: 'Make a Payment', phone: '1-800-472-9297', text: 'Pay your bill by phone. You can also make a payment',online:'online.', time: '24/7' },
          { title: 'Apply for a Card', phone: '1-888-297-1244', text: 'Apply for a Personal card today. You can also apply',online:'online.', time: '6 AM - 2 AM EST, 7 DAYS' },
          { title: 'Check Application Status', phone: '1-877-239-3491', text: 'Ask about the application status of a new account.', time: 'MON – FRI: 8 AM - 12 AM EST | SAT: 10 AM - 6:30 PM EST' },
        ]
      }
    }
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
  .font {
    font-size: 11px;
    font-weight: 400;
  }
  </style>
  